import { TFunction } from 'i18next'

import { directYoutubeRegExp } from 'pages/product/update/formUtils/formValidation'
import { AgencyDTO } from 'types/agencies/agency'
import { ImageMetaDTO } from 'types/common/attachments'

const MB = 'Mb'
const KB = 'Kb'

export const getFileSize = (fileSize: number, numberLocale: string, t: TFunction<['common']>) => {
  const fileSizeLength = fileSize.toString().length
  const kilobytes = Math.round(fileSize / 1024)
  const megabytes = kilobytes / 1000

  if (fileSizeLength < 7) {
    return `${t('common|one_digit_number', { value: kilobytes, lng: numberLocale })}${KB}`
  }

  return `${t('common|one_digit_number', { value: megabytes, lng: numberLocale })}${MB}`
}

export const getFileExtensionFromUrl = (url?: string): string => {
  const matchedExtension = (url || '').match(/.([^.]+)\?X-Goog-Algorithm/)
  return (matchedExtension || [])[1]
}

export const getFileExtensionFromKey = (key: string) => key.split('.').pop()

export const getKeysFromImageMeta = (meta: ImageMetaDTO | null | undefined) =>
  [meta?.thumbnail?.key, meta?.original?.key].filter(Boolean) as string[]

export const getKeysOfAgencyImages = (agency: AgencyDTO | null | undefined) =>
  [agency?.logoThumbnail?.key, agency?.logoOriginal?.key].filter(Boolean) as string[]

export const getDataUrl = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader()

    reader.onabort = () => reject('File reading was aborted')

    reader.onerror = () => reject('File reading has failed')

    reader.onload = () => resolve(reader.result as string)

    reader.readAsDataURL(file)
  })

export const createVideoThumbnailImg = (url: string): string => {
  if (url.match(/youtube\.com|youtu\.be/gm)) {
    let id: string | null = ''
    if (url.match(/youtube\.com\/embed|youtu\.be/gm)) {
      const splitUrl = url.split('/')
      id = splitUrl[splitUrl.length - 1]
    } else {
      id = new URL(url).searchParams.get('v')
    }
    return `https://img.youtube.com/vi/${id}/hqdefault.jpg`
  } else {
    const vimeoId = (url.match(/vimeo\.com\/video\/(\d+)/) || url.match(/vimeo\.com\/(\d+)/) || [])[1]
    const placeholderImg = 'https://img.youtube.com/vi/default.jpg'
    return vimeoId ? `https://vumbnail.com/${vimeoId}.jpg` : placeholderImg
  }
}

export const adjustVideoLink = (link: string) => {
  const splitUrl = link.split('/')
  const id = splitUrl[splitUrl.length - 1]
  if (link.includes('youtu.be')) {
    return `https://www.youtube.com/embed/${id}`
  }
  if (link.includes('youtube')) {
    return link.match(directYoutubeRegExp)
      ? `https://www.youtube.com/embed/${new URL(link).searchParams.get('v')}`
      : link
  }
  return `https://player.vimeo.com/video/${id}`
}
